import React from "react";

import Layout from "../components/layout";
import { Seo } from "../components";
import SermonNotes from "../container/sermon-notes";
import { BACKEND_BASE_URL } from "../constant/constants";

const SermonNotesPage = ({ serverData, location }) => {
  return (
    <Layout location={location}>
      <Seo title="Worship Resource" />
      <SermonNotes data={serverData.sermon} />
    </Layout>
  );
};

export async function getServerData(context) {
  try {
    const sermon = await fetch(
      BACKEND_BASE_URL + "worship-resources/sermon-reading"
    );

    if (!sermon.ok) {
      throw new Error(`Response failed`);
    }

    return {
      props: {
        sermon: await sermon.json(),
      },
    };
  } catch (error) {
    console.log(error);
    return {
      status: 500,
      headers: {},
      props: {},
    };
  }
}

export default SermonNotesPage;
