import React from "react";

import { Breadcrumb } from "../components";
import { BookCard } from "../components/book-card";

const breadcrumbData = [
  {
    id: 1,
    name: "Home",
    link: "/",
  },
  {
    id: 2,
    name: "Worship Resources",
  },
];

const SermonNotes = props => {
  const { data } = props;

  return (
    <div className="book-details">
      <div className="container">
        <div className="mt-5">
          <Breadcrumb data={breadcrumbData} />
        </div>
        <h3 className="book-details__title">Worship Resources</h3>
        <span className="book-details__sub-title mb-4 d-flex">
          Sermon Notes
        </span>
        <div className="row">
          {data?.map(book => {
            return (
              <div className="col-12 col-md-4 mb-4">
                <BookCard book={book} />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
export default SermonNotes;
