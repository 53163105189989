import React from "react";

const BookCard = props => {
  const { book } = props;

  return (
    <div className="book-details__card">
      <div className="book-details__cardDetails">
        <img
          src={book?.image_url}
          alt="quote-mark-logo"
          className="book-details__cardImg"
        />
        <h6 className="book-details__year">{book?.title}</h6>
        <div className="book-details__readmore">
          <a
            href={book?.asset_full_url}
            target="_blank"
            rel="noopener noreferrer"
            className="book-details__readBtn"
          >
            Read More
          </a>
        </div>
      </div>
    </div>
  );
};

export { BookCard };
